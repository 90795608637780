import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Blog from '../components/Blog/Blog';
import Button from '../components/Button/Button';
import Image from '../components/Image/Image';
import useContent from '../content/useContent';

const Post2 = () => {
  const blogPost2 = useContent('blogPost2');
  if (!blogPost2) return null;

  const {
    img,
    desc,
    title,
    excerpt,
    firstP,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    additional,
  } = blogPost2;

  return (
    <Blog title={title} img={img} desc={desc} excerpt={excerpt} smallerImg>
      <>
        <div className='col-2-1'>
          <p>{firstP}</p>
          <Image filename='zdrowy-sklep-post-2' />
        </div>
        <div>
          <h2>{sectionOne.title}</h2>
          <p>{sectionOne.firstP}</p>
        </div>
        <div className='col-1-2'>
          <Image filename='zdrowy-sklep-post' />
          <div>
            <h2>{sectionTwo.title}</h2>
            <p>{sectionTwo.firstP}</p>
          </div>
        </div>
        <div>
          <h2>{sectionThree.title}</h2>
          <p>{sectionThree.firstP}</p>
        </div>
        <div>
          <h2>{sectionFour.title}</h2>
          <p>{sectionFour.firstP}</p>
          <ul>
            <li>{sectionFour.list.pos1}</li>
            <li>{sectionFour.list.pos2}</li>
            <li>{sectionFour.list.pos3}</li>
          </ul>
        </div>
        <div className='col-2-1'>
          <div>
            <h2>{sectionFive.title}</h2>
            <p>{sectionFive.firstP}</p>
          </div>
          <Image filename='zdrowy-sklep-post-3' />
        </div>
        <div className='additional'>
          <p>
            <sup>1</sup> <em>{additional}</em>
          </p>
        </div>
      </>
    </Blog>
  );
};

export default Post2;
