import React from 'react';
import Contact from '../Contact/Contact';
import FreeRaport from '../FreeRaport/FreeRaport';
import Image from '../Image/Image';
import Layout from '../Layout/Layout';
import SEO from '../seo';

const Blog = ({ children, title, desc, excerpt, img, isRaport = false, smallerImg = false }) => (
  <Layout type='blog' onlyPL>
    <SEO title='' lang='PL' />
    <main className='blog'>
      <div className='head section section--dark'>
        <div className='head__inside boxed'>
          <div className={`head__img ${smallerImg ? 'small' : ''}`}>
            <Image filename={img} />
          </div>
          <h3>{desc}</h3>
          <h1>{title}</h1>
        </div>
      </div>
      <div className='content boxed'>
        <p className='block'>
          <strong>{excerpt}</strong>
        </p>
        <div>{children}</div>
      </div>
      {isRaport && <FreeRaport />}
    </main>
    <Contact />
  </Layout>
);

export default Blog;
